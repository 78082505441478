import React from 'react'
import axios from "axios";
import { useState } from 'react'

function Vistausuario(props) {

    const [messageShow, setMessage] = useState({
        msg: ''})
    const [pdfShow, setpdf] = useState({
            pdf: ''})

    function onButtonClick(event) {


        const str = '/certificado' + event.currentTarget.id;
        console.log( str)
        axios({
        method: "GET",
        url: str,
  
        headers :{      Authorization: 'Bearer ' + props.token,
                       
                  }
        }).then((response) => {
            const res =response.data
  
                setMessage(({msg: res.msg}))
                setpdf(({pdf: res.archivo}))
                res.access_token && props.setToken(res.access_token)

                fetch(res.archivo).then(response => {
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = res.archivo;
                        alink.click();
                    })
                })
           
        }).setTimeout(() => { axios({
            method: "DELETE",
            url:`/remove_file/CertificadoLaboral_12345.pdf`,
      
            headers :{ Authorization: 'Bearer ' + props.token,
                           
                      }
            }).then((response) => {
            

            }); }, 30000).catch((error) => {
        if (error.response) {
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
          }
        })

        console.log(pdfShow.pdf)
     
          
       
     
    }
  
  return (
      <div>
              <div className="text-center mx-auto mt-5 mb-5" style={{maxWidth: '500px'}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Descargas</h5>
                <h1 className="display-4">
                    Certificados</h1>
            </div>
            <div className="row m-5">
            <div className="col-sm-4 mb-4  col-6 " >
                <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
       
                    <h5 className="mb-0 text-dark">Certificado de Nómina</h5>
                    <button id ='nomina' onClick={onButtonClick} className="btn mt-3 btn-primary w-50 py-3" value='nomina'>Descargar</button>
                </div>
            </div>
            <div className="col-sm-4 mb-4 col-6">
                <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                     <form className="login">
                    <h5 className="mb-0 me-5 ms-5 text-dark">Certificado de Ingresos y Retenciones por Rentas de Trabajo y de Pensiones </h5>
                    <button id ='retenciones' onClick={onButtonClick} value='retenciones' className="btn mt-3 btn-primary w-50 py-3 " >Descargar</button>
                    </form>
                </div>
            </div>

            <div className="col-sm-4 mb-4 col-6">
                <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                    <p className='text-dark bg-warning h6'>{messageShow.msg}</p>
                    <h5 className="mb-0 text-dark">Certificado Laboral</h5>
                    <button id ='laboral' onClick={onButtonClick} value='laboral' className="btn mt-3 btn-primary w-50 py-3 " type="submit">Descargar</button>
                </div>
            </div>
            </div>
    </div>
    )
}

export default Vistausuario