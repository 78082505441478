import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
 
import './App.css';
import Home from './pages/home';
import  Servicios from './pages/servicios';
import Contact from './pages/contacto';
import Login from './components/log';
import Usuario from './components/vistausuario';
import useToken from './components/useToken'
import PageWrapper from './components/PageWrapper';
import Recuperar from './components/Recuperar';
import axios from "axios";
import Restore from './components/restore';

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

const BASE_URL = 'http://localhost:3000/';

export const authApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

function App() {
    const { token, removeToken, setToken,getToken } = useToken();
    // object for storing and using data {!token && token!=="" &&token!== undefined?      :(         )}

  return (
    <div className="App">
       
      <Router>
        <PageWrapper token={token} removeToken={removeToken}>
        <Routes>
   
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/servicios" element={<Servicios/>}/>
        <Route exact path="/contacto" element={<Contact/>}/>

   
        {!token && token!=="" &&token!== undefined? 
        <>
        <Route exact path="/login"  element={<Login setToken={setToken}/>}/> 
        <Route exact path="/recuperarcontrasena"  element={<Recuperar setToken={setToken}/>}/> 
        <Route exact path="/recuperar/:id"  element={<Restore getToken={getToken} setToken={setToken}/>}/> 
        </>
        :( 
        <Route exact path="/usuario" element={<Usuario  token={token} setToken={setToken}/>}/>
        
        )}
        <Route exact path='*' element={<Home />}/>

        {AddLibrary( "./assets/js/main.js")}
        {AddLibrary( "./assets/lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js")}
        {AddLibrary( "./assets/lib/tempusdominus/js/moment-timezone.min.js")}
        {AddLibrary( "./assets/lib/tempusdominus/js/moment.min.js")}
        {AddLibrary( "./assets/lib/owlcarousel/owl.carousel.min.js")}
        {AddLibrary( "./assets/lib/waypoints/waypoints.min.js")}
        {AddLibrary( "./assets/lib/easing/easing.min.js")}
  
        
        </Routes>
        </PageWrapper>
       
      </Router>
  
    </div>
  );
}
export default App;
