import React from 'react'
import logo from './assets/img/logo.png'
import {useNavigate, useParams} from 'react-router-dom'
import { useState } from 'react';
import axios from "axios";


function Restore(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [messageShow, setMessage] = useState({
        msg: ''})
    const [loginForm, setloginForm] = useState({
      password: "",
      newpassword: "",
      token:""
      })
      const [token] = useState(props.getToken());

      function logMeIn(event) {
        axios({
          method: "POST",
          url:"/newpassword",
          data:{
            password: loginForm.password,
            newpassword: loginForm.newpassword,
            token: params.id
           },
          headers :{   Authorization: 'Bearer ' + token,
                }
      
        })
        .then((response) => {
          setMessage(({
            msg: response.data.msg}))

            if(response.data.val){
              navigate('/login')
           } else{
         

            }


          }).catch((error) => {
            

          if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
            }
        })
  
        setloginForm(({
          password: "",
          newpassword: "",
          token:""}))
  
        event.preventDefault()

      }
  
      function handleChange(event) { 
        const {value, name} = event.target
        setloginForm(prevNote => ({
            ...prevNote, [name]: value}))
        
        }
        function setVariable(event) { 
        props.setToken(params.id)

        }

  return (
    <div className=' rounded w-110' onload={setVariable}>

                
                    <div className="bg-white text-center mx-auto  rounded p-5 mt-4 mb-5 align-items-center justify-content-center text-center col-xl-6 col-lg-7">
                        <img className="fa fa-clinic-medical me-2 mb-5 m-auto"  src={logo} style={{Width:'60%',maxWidth: '300px'}} alt='' ></img>
                        <form className='mx-auto'>
                            <div className="row g-3">
                                <p className='text-dark bg-warning h6'>{messageShow.msg}</p>

                                <div className="col-12 d-flex flex-column align-items-center">
                                    
                                    <p className='text-dark h4'>Nueva Contraseña</p>
                                    <input onChange={handleChange} name="password" type="text" value={loginForm.password} text={loginForm.password} class="form-control bg-light border-0  text-center mb-3" placeholder="Ingrese Nombre de Usuario" style={{height: '55px', minWidth:'100px'}}/>
                                    <p className='text-dark h4'>Repetir Nueva Contraseña</p>
                                    <input onChange={handleChange} name="newpassword" type="password" value={loginForm.newpassword} text={loginForm.newpassword} class="form-control bg-light border-0  text-center mb-4" placeholder="Ingrese Contraseña" style={{height: '55px'}}/>

                                    
                               
                                </div>
                        
                                <div className="col-12">
                                    <button onClick={logMeIn} class="btn btn-primary w-50 py-3" type="submit" >Iniciar Sesión</button>
                                </div>
                            </div>
                        </form>
                    </div>
         
   
    </div>

    )
}

export default Restore