import React from 'react';

import Hero from '../components/hero';
import About from '../components/about';
import Mision from '../components/mision';
import Servicios from '../components/servicios';
import Formulario from '../components/formulario';
import Valores from '../components/valores';
import Proposito from '../components/proposito';


export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }
  
function Home () {
        return (
            <>

           <Hero />
        
           <About />
           <Mision />
           <Servicios />
           <Formulario />
           <Valores />
           <Proposito />
        
       

           {AddLibrary( "./assets/js/main.js")}
            {AddLibrary( "./assets/lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js")}
            {AddLibrary( "./assets/lib/tempusdominus/js/moment-timezone.min.js")}
            {AddLibrary( "./assets/lib/tempusdominus/js/moment.min.js")}
            {AddLibrary( "./assets/lib/owlcarousel/owl.carousel.min.js")}
            {AddLibrary( "./assets/lib/waypoints/waypoints.min.js")}
            {AddLibrary( "./assets/lib/easing/easing.min.js")}
            
            </>
)
}

export default Home;