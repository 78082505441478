import React from 'react'
import logo from './assets/img/logo.png'
import {Link, useNavigate} from 'react-router-dom'
import { useState } from 'react';
import axios from "axios";


function Log(props) {

    const navigate = useNavigate();
    const [messageShow, setMessage] = useState({
        msg: ''})
    const [loginForm, setloginForm] = useState({
        id: "",
        password: ""
      })
  
      function logMeIn(event) {
        axios({
          method: "POST",
          url:"/token",
          data:{
            id: loginForm.id,
            password: loginForm.password
           }
        })
        .then((response) => {
        
          props.setToken(response.data)
          navigate('/usuario')
           
        }).catch((error) => {
            setMessage(({
                msg: 'Usuario o contraseña errados'}))
                
          if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
            }
        })
  
        setloginForm(({
          id: "",
          password: ""}))
  
        event.preventDefault()

      }
  
      function handleChange(event) { 
        const {value, name} = event.target
        setloginForm(prevNote => ({
            ...prevNote, [name]: value})
        )}

  return (
    <div className=' rounded w-110'>

                
                    <div className="bg-white text-center mx-auto  rounded p-5 mt-4 mb-5 align-items-center justify-content-center text-center col-xl-6 col-lg-7">
                        <img className="fa fa-clinic-medical me-2 mb-5 m-auto"  src={logo} style={{Width:'60%',maxWidth: '300px'}} alt='' ></img>
                        <form className='mx-auto'>
                            <div className="row g-3">
                                <p className='text-dark bg-warning h6'>{messageShow.msg}</p>

                                <div className="col-12 d-flex flex-column align-items-center">
                                    
                                    <p className='text-dark h4'>Usuario</p>
                                    <input onChange={handleChange} name="id" type="text" value={loginForm.id} text={loginForm.id} class="form-control bg-light border-0  text-center mb-3" placeholder="Ingrese Nombre de Usuario" style={{height: '55px', minWidth:'100px'}}/>
                                    <p className='text-dark h4'>Contraseña</p>
                                    <input onChange={handleChange} name="password" type="password" value={loginForm.password} text={loginForm.password} class="form-control bg-light border-0  text-center mb-4" placeholder="Ingrese Contraseña" style={{height: '55px'}}/>
                                    <a href='#!'><Link style={{fontSize:'80%'}} to="/recuperarcontrasena">Olvidó su contraseña</Link></a>
                                    
                               
                                </div>
                        
                                <div className="col-12">
                                    <button onClick={logMeIn} class="btn btn-primary w-50 py-3" type="submit" >Iniciar Sesión</button>
                                </div>
                            </div>
                        </form>
                    </div>
         
   
    </div>

    )
}

export default Log