import React from 'react'



function Proposito() {
  return (
    <div>
        <div className="container-fluid my-3 py-2">
            <div className="container py-5">
                <div className="text-center mx-auto m-2" style={{maxWidth: '900px'}}>
                    <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Propuesta de valor</h5>
                    <h5 className="display-6 mb-4">Gestionar el talento humano garantizando el desarrollo organizacional de nuestros aliados estratégicos, impactando positivamente en el fortalecimiento de nuestros colaboradores y la productividad de nuestros clientes.</h5>
                    <h5 className="text-white fw-normal">Atención a Nivel Nacional</h5>
                </div>

            </div>
        </div>
    </div>

    )
}

export default Proposito