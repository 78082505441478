import React from 'react'
import {HashLink as Scroll} from 'react-router-hash-link'
import suministro from './assets/img/suministro1.png'
import bussines from './assets/img/bussines1.png'

import reclutamiento from './assets/img/reclutamiento1.png'
import logistica from './assets/img/logistica1.png'
import seguridad from './assets/img/seguridad1.png'

import mas from './assets/img/mas1.png'



function Servicios() {
  return (
    <div>
    <div className="container-fluid py-5 bg-dark" id='todoslosservicios'>
        <div className="container">
            <div className="text-center mx-auto mb-4" style={{maxWidth: '500px'}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5>
                <h1 className="display-4">Nuestros Servicios</h1>
            </div>
            <div className="row">
                <div className=" col-sm-4 mb-4 col-6">
                    <div  className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"  style={{maxHeight:'300px', maxWidth: '830px'}}>
                        <div className="service-icon mb-4 bg-white">
                            <img src={suministro} style={{width:'25%', height: "35%", transform: 'rotate(14deg)'}} alt=''></img>
                        </div>
                        <h4 className="mb-3 text-dark">Suministro de personal</h4>
                        <a className="btn btn-lg btn-primary rounded-pill" href="#!">
                        <Scroll to="#suministro"><i class="bi bi-arrow-right"></i> </Scroll>
                        </a>
                    
                    </div>
                </div>
                <div className="col-sm-4 mb-4 col-6">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{maxHeight:'300px', maxWidth: '830px'}}>
                        <div className="service-icon mb-4 bg-white">
                            <img src={bussines} style={{width:'25%', height: "35%", transform: 'rotate(14deg)'}} alt=''></img>
                        </div>
                        <h4 className="mb-3 text-dark">Business Process Outsourcing</h4>
                        <a className="btn btn-lg btn-primary rounded-pill" href="#!">
                        <Scroll to="#bpo"><i class="bi bi-arrow-right"></i> </Scroll>
                        </a>
                    </div>
                </div>

                <div className="col-sm-4 mb-4 col-6 ">
                    <div className="service-item bg-light  rounded d-flex flex-column align-items-center justify-content-center text-center" style={{maxHeight:'300px', maxWidth: '830px'}}>
                        <div className="service-icon mb-4 bg-white">
                            <img src={reclutamiento} style={{width:'25%', height: "35%", transform: 'rotate(14deg)'}} alt=''></img>
                        </div>
                        <h4 className="mb-3 text-dark">Head Hunter</h4>
                        <a className="btn btn-lg btn-primary rounded-pill" href="#!">
                        <Scroll to="#head"><i class="bi bi-arrow-right"></i></Scroll>
                        </a>
                    </div>
                </div>
            
                <div className="col-sm-4 mb-4 col-6">
                    <div className="service-item bg-light  rounded d-flex flex-column align-items-center justify-content-center text-center" style={{maxHeight:'300px', maxWidth: '100%'}}>
                        <div className="service-icon mb-4 bg-white">
                            <img src={logistica} style={{width:'25%', height: "35%", transform: 'rotate(14deg)'}} alt=''></img>
                        </div>
                        <h4 className="mb-3 text-dark">Outsourcing Operativo</h4>
                        <a className="btn btn-lg btn-primary rounded-pill" href="#!">
                            <Scroll to="#outsourcing"><i class="bi bi-arrow-right"></i></Scroll>
                        </a>
                    </div>
                </div>
                <div className="col-sm-4 mb-4 col-6">
                    <div className="service-item bg-light  rounded d-flex flex-column align-items-center justify-content-center text-center" style={{maxHeight:'300px', maxWidth: '100%'}}>
                        <div className="service-icon mb-4 bg-white">
                            <img src={seguridad} style={{width:'25%', height: "35%", transform: 'rotate(14deg)'}} alt=''></img>
                        </div>
                        <h4 className="mb-3 text-dark">Seguridad Trabajo
                            y Salud en el trabajo</h4>
                        <a className="btn btn-lg btn-primary rounded-pill" href="#!">
                            <Scroll to="#seguridad"><i class="bi bi-arrow-right"></i></Scroll>
                        </a>
                    </div>
                </div>

                <div className="col-sm-4 mb-4 col-6 " >
                    <div className="service-item  bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{maxHeight:'300px', maxWidth: '100%'}}>
                        <div className="service-icon mb-4 bg-white">
                            <img src={mas} style={{width:'25%', height: "35%", transform: 'rotate(14deg)'}} alt=''></img>
                        </div>
                        <h4 className="mb-3 text-dark">Otros</h4>
                        <a className="btn btn-lg btn-primary rounded-pill" href="#!">
                        <Scroll to="#otros"><i class="bi bi-arrow-right"></i></Scroll>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    )
}

export default Servicios