import React from 'react'


function Footer() {
  return (
  
    <footer>
    <div className="container-fluid bg-primary text-light mt-1 py-5">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="d-inline-block text-dark text-uppercase border-bottom border-5 border-secondary mb-4">Barranquilla</h4>
                    <p className="mb-2 text-dark"><i className="fa fa-map-marker-alt text-primary me-3"></i>Calle 79 #47 - 35, oficina 3</p>
                    <p className="mb-2 text-dark"><i className="fa fa-envelope text-primary me-3"></i>gerencia@sertempco.co</p>
                    <p className="mb-0 text-dark"><i className="fa fa-phone-alt text-primary me-3"></i>3106495641</p>
                </div>
                <div className="col-lg-3 col-md-6 text-dark">
                    <h4 className="d-inline-block text-dark text-uppercase border-bottom border-5 border-secondary mb-4">Pereira</h4>
                    <div className="d-flex flex-column justify-content-start">
                    <p className="mb-2 text-dark"><i className="fa fa-map-marker-alt text-primary me-3"></i>Calle 19 #8 - 34, piso 5 oficina 09</p>
                    <p className="mb-2 text-dark"><i className="fa fa-envelope text-primary me-3"></i>gerencia@sertempco.co</p>
                    <p className="mb-0 text-dark"><i className="fa fa-phone-alt text-primary me-3"></i>3245510201</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="d-inline-block text-dark text-uppercase border-bottom border-5 border-secondary mb-4">Horarios</h4>
                    <p className="mb-2 text-dark"><i className="fa fa-map-marker-alt text-primary me-3"></i>Lunes a Viernes</p>
                    <p className="mb-2 text-dark"><i className="fa fa-map-marker-alt text-primary me-3"></i>8:00 am a 12:00 pm</p>
                    <p className="mb-2 text-dark"><i className="fa fa-map-marker-alt text-primary me-3"></i>2:00 pm a 6:00 pm</p>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="d-inline-block text-dark text-uppercase border-bottom border-5 border-secondary mb-4">Siguenos</h4>
                    <div className="d-flex align-items-center justify-content-center text-center">
                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#!"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#!"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#!"><i className="fab fa-linkedin-in"></i></a>
                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href="#!"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-primary text-light border-top border-secondary py-4">
        <div className="container">
            <div className="row g-5">
                <div className="col-md-6 text-md-start">
                    <p className="mb-md-0 text-dark">&copy; <a className="text-primary" href="#!">Sertepmco</a>. All Rights Reserved.</p>
                </div>
                <div className="col-md-6  text-md-end">
                    <p className="mb-0"><a className="text-primary" href="https://nlanub.com">Nlanub</a></p>
         
                 
                </div>
            </div>
        </div>
    </div>
</footer>


    )
}

export default Footer