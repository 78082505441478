import React from 'react'
import suministro from './assets/img/suministro1.png'
import bussines from './assets/img/bussines1.png'

import reclutamiento from './assets/img/reclutamiento1.png'
import logistica from './assets/img/logistica1.png'
import seguridad from './assets/img/seguridad1.png'

import mas from './assets/img/mas1.png'



function Servicios() {
  return (
    <div>
<div className="container-fluid py-5 bg-white" id='todosservices'>
        <div className="container">
            <div className="text-center mx-auto mb-5 " style={{maxWidth: "500px"}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5>
                <h1 className="display-4 text-dark">Nuestros servicios</h1>
            </div>
            <div className="row ">
                <div className="col-sm-4 mb-4 col-6 " >
                    <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                        <img src={suministro} alt='' style={{height:'15%', width:'15%'}}></img>
                        <h5 className="mb-0 text-dark">Suministro de personal</h5>
                    </div>
                </div>
                <div className="col-sm-4 mb-4 col-6">
                    <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                        <img src={bussines} alt=''style={{height:'15%', width:'15%'}}></img>
                        <h5 className="mb-0 text-dark">Business Process Outsourcing</h5>
                    </div>
                </div>
 
                <div className="col-sm-4 mb-4 col-6">
                    <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                        <img src={reclutamiento} alt='' style={{height:'15%', width:'15%'}}></img>
                        <h5 className="mb-0 text-dark">Head Hunter</h5>
                    </div>
                </div>
          
                <div className="col-sm-4 mb-4 col-6">
                    <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                        <img src={logistica} alt='' style={{height:'15%', width:'15%'}}></img>
                        <h5 className="mb-0 text-dark">Outsourcing Operativo</h5>
                    </div>
                </div>
                <div className="col-sm-4 mb-4 col-6">
                    <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                        <img src={seguridad} alt='' style={{height:'15%', width:'15%'}}></img>
                        <h5 className="mb-0 text-dark">Seguridad Trabajo y Salud en el trabajo</h5>
                    </div>
                </div>

                <div className="col-sm-4 mb-4 col-6">
                    <div className="bg-light text-center rounded d-flex flex-column align-items-center justify-content-center text-center" style={{minHeight:'250px'}}>
                        <img src={mas} alt='' style={{height:'15%', width:'15%'}}></img>
                        <h5 className="mb-0 text-dark">Otros</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    )
}

export default Servicios