import React from 'react';

import Services from '../components/services';
import Descripcionservices from '../components/descripcionservices';


function Home () {
        return (
            <>

     
           <Services />
           <Descripcionservices />
    
            </>
)
}

export default Home;