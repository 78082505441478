import React from 'react'
import axios from "axios";
import { useState } from 'react';


function Formulario(props) {

    const [messageShow, setMessage] = useState({
        msg: ''})
    const [formularioForm, setformularioForm] = useState({
        nombre: "",
        correo: "",
        asunto:"",
        mensaje:""

      })
     
  
      function logMeIn(event) {
        axios({
          method: "POST",
          url:"/correo",
          data:{
            nombre: formularioForm.nombre,
            correo: formularioForm.correo,
            asunto: formularioForm.asunto,
            mensaje: formularioForm.mensaje
           }
        })
        .then((response) => {
            
            setMessage(({
                msg: response.data.msg}))
           
        }).catch((error) => {
            props.setMessage(({
                msg: 'Verifique los datos registrados'}))
          if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
            }
        })
  
        setformularioForm(({
        nombre: "",
        correo: "",
        asunto:"",
        mensaje:""}))
  
        event.preventDefault()

      }
  
      function handleChange(event) { 
        const {value, name} = event.target
        setformularioForm(prevNote => ({
            ...prevNote, [name]: value})
        )}




  return (
<div className="container-fluid bg-dark my-5 py-5">
        <div className="container py-5">
            <div className="row gx-5">
                <div className="col-lg-6 mb-5 mb-lg-0">
                    <div className="mb-4">
                        <h5 className="d-inline-block text-white text-uppercase border-bottom border-5">Contáctanos</h5>
                        <h1 className="display-4 text-white">Agenda una cita con nuestro equipo</h1>
                    </div>
                    <p className="text-white mb-5">Conviertenos en el aliado estratégico que le permitirá enfocarse en le Core de su empresa, delegando en nosotros el activo más importante de su compañía como lo es la gestión y administración del talento humano.</p>
                    <a href='#!' className="btn btn-dark rounded-pill py-3 px-5 me-3">Más información</a>
                </div>
                <div className="col-lg-6">
                    <div className="bg-white text-center rounded p-5">

                        <form>
                            <div className="row g-3">
                                <p className='text-dark bg-warning h6'>{messageShow.msg}</p>
                                <div className="col-12 col-sm-6">
                                    <input onChange={handleChange} name='nombre' value={formularioForm.nombre} text={formularioForm.nombre} type="text" className="form-control bg-light border-0" placeholder="Nombre" style={{height: '55px'}}></input>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input onChange={handleChange}  name='correo' value={formularioForm.correo} text={formularioForm.correo} type="email" className="form-control bg-light border-0" placeholder="Correo" style={{height: '55px'}}></input>
                                </div>
                                <div className="col-12">
                                    <input onChange={handleChange}  name='asunto'  value={formularioForm.asunto} text={formularioForm.asunto} type="text" className="form-control bg-light border-0" placeholder="Asunto" style={{height: '55px'}}></input>
                                </div>
                                <div className="col-12">
                                    <textarea onChange={handleChange}  name='mensaje' value={formularioForm.mensaje} text={formularioForm.mensaje} className="form-control bg-light border-0" rows="5" placeholder="Mensaje"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit" onClick={logMeIn} >Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    )
}

export default Formulario