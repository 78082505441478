import React from 'react'
import mision from './assets/img/mision.jpg'
import vision from './assets/img/vision.jpg'


function Mision() {
  return (
    <div>
    <div className="container-fluid py-5" >
        <div className="container " >
            <div className="text-center mx-auto mb-2" style={{maxWidth: '800px'}}>
                <h1 className="display-4 mb-4 text-white">Lineamientos estratégicos</h1>
            </div>
            <div className="row" style={{margin: '0px'}}>
                <div className="col-xl-6 col-lg-7" style={{borderstyle: 'inset',margin: '0px'}}>
                   
                    <div  className=" rounded" style={{height:'100%'}}>
                    
                        <div  id='something' className="p-4" style={{marginBottom:'.63rem',height:'100%'}}>
                            <img src={mision} alt='' style={{width:'80%',maxHeight:'80%',maxWidth:'80%'}}></img>
                            <p id='something2' className="text-center  mb-3 mt-3" href="#!"  style={{fontSize:'1.75rem',fontFamily:"Roboto Condensed",fontWeight: 'bold'}}>MISIÓN</p>
                            <p className="mt-2 " style={{fontSize:'130%', textAlign:'justify'}}>Proveer, administrar y gestionar talento humano en misión con el fin de
                                 lograr un desarrollo organizacional y contribuir a la competitividad de nuestros clientes; 
                                 para ello contamos con personal altamente calificado, principios éticos, valores, tecnología 
                                 de la información y excelente atención al cliente.</p>
                        </div>
                    
                    
                        
                    </div>
                </div>
                <div className="col-xl-6 col-lg-7" style={{borderstyle: 'inset',margin: '0px'}}>
                    <div  className=" rounded" style={{height:'100%'}}>
                        
                        <div  id='something' className="p-4" style={{marginBottom:'.63rem',height:'100%'}}>
                            <img src={vision} alt='' style={{width:'80%',maxHeight:'80%',maxWidth:'80%'}}></img>
                            <p  id='something2' className="  text-center  mb-3 mt-3" href="#!" style={{fontSize:'1.75rem',fontFamily:"Roboto Condensed",fontWeight: 'bold'}}>VISIÓN</p>
                            <p   className="mt-2 " style={{fontSize:'130%', textAlign:'justify'}} >Sertempco Group S.A.S.  -  E.S.T. espera ser reconocida a nivel nacional 
                                como una empresa con ética profesional, confiable, segura y garante en la provisión, administración 
                                y gestión de talento humano en misión.</p>
                        </div>
                      
                          
                    
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    )
}

export default Mision