import React from 'react'
import confiar from './assets/img/confiar.png'
import favorito from './assets/img/favorito.png'
import seleccionar from './assets/img/seleccionar.png'
import calidad from './assets/img/calidad.png'
import responsabilidad from './assets/img/responsabilidad.png'


function Valores() {
  return (
    <div>
            <div className="container-fluid py-5 bg-white">
        <div className="container">
            <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Valores</h5>
            </div>
            <div className="row justify-content-center">
                <div class="col-lg-8">
                    <div className="owl-carousel testimonial-carousel">
                        <div className="testimonial-item d-flex flex-column text-center align-items-center justify-content-center">
                            <img src={confiar} alt=''></img>
                            <hr className="w-25 mx-auto bg-dark"></hr>
                            <h3 className="text-dark">Integridad</h3>
                            <p className="fs-4 fw-normal text-dark">Damos alto valor a la honestidad, respeto y transparencia en nuestra interacción profesional y personal.</p>
                          
                        </div>
                        <div className="testimonial-item d-flex flex-column text-center align-items-center justify-content-center">
                            <img src={favorito} alt=''></img>
                            <hr className="w-25 mx-auto bg-dark"></hr>
                            <h3 className="text-dark">Responsabilidad</h3>
                            <p className="fs-4 fw-normal text-dark">Cumplimos los compromisos adquiridos y nuestro accionar es coherentes con nuestras políticas y objetivos organizacionales.</p>
                        
                        </div>
                        <div className="testimonial-item d-flex flex-column text-center align-items-center justify-content-center">
                            <img src={seleccionar} alt=''></img>
                            <hr className="w-25 mx-auto bg-dark"></hr>
                            <h3 className="text-dark">Servicio</h3>
                            <p className="fs-4 fw-normal text-dark">Mantenemos una actitud y disposición de servicio con nuestros clientes internos y externos en aras de dar soluciones optimas y oportunas.</p>
                        
                        </div>
                        <div className="testimonial-item d-flex flex-column text-center align-items-center justify-content-center">
                            <img src={calidad} alt=''></img>
                            <hr className="w-25 mx-auto bg-dark"></hr>
                            <h3 className="text-dark">Calidad</h3>
                            <p className="fs-4 fw-normal text-dark">Realizamos nuestra labor con profesionalismo para lograr eficiencia en cada uno de nuestros procesos.</p>
                        
                        </div>
                        <div className="testimonial-item d-flex flex-column text-center align-items-center justify-content-center">
                            <img src={responsabilidad} alt=''></img>
                            <hr className="w-25 mx-auto bg-dark"></hr>
                            <h3 className="text-dark">Trabajo en equipo</h3>
                            <p className="fs-4 fw-normal text-dark">Identificamos las fortalezas y debilidades de cada uno de nuestros colaboradores, compartimos conocimiento, experiencias y responsabilidades para trabajar por un objetivo en común.</p>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    )
}

export default Valores