import React from 'react'
import {Link} from 'react-router-dom'
import logo from './assets/img/logoblanco.png'
import Footer from '../components/footer';
import Header from '../components/header';
import axios from "axios";

function PageWrapper(props) {

   
    console.log(props.token)

    function logMeOut() {
        axios({
          method: "POST",
          url:"/logout",
        })
        .then((response) => {
            props.removeToken()
        }).catch((error) => {
          if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
            }
        })}



    
  return (

    <div>
        <Header />
    <div className="container-fluid sticky-top bg-dark shadow-sm">
        <div className="container">
            <nav className="navbar navbar-expand-lg  navbar-light py-3 py-lg-0">
                <a href="index.html" className="navbar-brand">
                <Link to="/"> <img className="fa fa-clinic-medical me-2"  src={logo} style={{maxWidth: '250px'}} alt='' ></img></Link>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <li className="nav-item"><Link className="nav-link" to="/">Inicio</Link ></li>
                        <li className="nav-item"><Link className="nav-link" to="/servicios">Servicios</Link ></li>
                        <li className="nav-item"><Link className="nav-link" to="/contacto">Contacto</Link></li>
                        
                        {!props.token && props.token!=="" && props.token!== undefined?  
                            <li className="btn btn-dark text-center rounded-pill py-md-3 px-md-4 m-3" href=""><Link to="/login">INICIA SESIÓN</Link></li>
                        :(  
                            <>
                            <li className="nav-item"><Link className="nav-link" to="/usuario">Descargas</Link></li>
                            <li className="btn btn-dark text-center rounded-pill py-md-3 px-md-4 m-3" href="" onClick={logMeOut}><Link to="/">CERRAR SESIÓN</Link></li>
                            </>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    </div>
        {props.children}
    <Footer/>
    </div>

  )
}

export default PageWrapper
