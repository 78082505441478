import React from 'react'
import axios from "axios";
import { useState } from 'react';

function Contacto(props) {

    const [messageShow, setMessage] = useState({
        msg: ''})
    const [contactForm, setfcontactForm] = useState({
        nombre: "",
        correo: "",
        asunto:"",
        mensaje:""

      })
     
  
      function logMeIn(event) {
        axios({
          method: "POST",
          url:"/correo",
          data:{
            nombre: contactForm.nombre,
            correo: contactForm.correo,
            asunto: contactForm.asunto,
            mensaje: contactForm.mensaje
           }
        })
        .then((response) => {
            
            setMessage(({
                msg: response.data.msg}))
           
        }).catch((error) => {
            props.setMessage(({
                msg: 'Verifique los datos registrados'}))
          if (error.response) {
            console.log(error.response)
            console.log(error.response.status)
            console.log(error.response.headers)
            }
        })
  
        setfcontactForm(({
        nombre: "",
        correo: "",
        asunto:"",
        mensaje:""}))
  
        event.preventDefault()

      }
  
      function handleChange(event) { 
        const {value, name} = event.target
        setfcontactForm(prevNote => ({
            ...prevNote, [name]: value})
        )}


  return (
    <div>
    <div className="container-fluid pt-5">
        <div className="container">
            <div className="text-center mx-auto mb-5" style={{maxWidth: '500px'}}>
                <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Contáctanos</h5>
                <h1 className="display-4">
                    Atención a Nivel Nacional</h1>
            </div>
            <h2 className="display-4">
                    Barranquilla</h2>
            <div className="row g-5 mb-5">
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:' 100px', height: '70px', transform:'rotate(-15deg)'}}>
                            <i className="fa fa-2x fa-location-arrow text-primary" style={{transform: 'rotate(15deg)'}}></i>
                        </div>
                        <h6 className="mb-0 text-dark">Calle 79 #47 - 35, oficina 3</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:' 100px', height: '70px', transform:'rotate(-15deg)'}}>
                            <i className="fa fa-2x fa-phone text-primary" style={{transform: 'rotate(15deg)'}}></i>
                        </div>
                        <h6 className="mb-0 text-dark">3106495641</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:' 100px', height: '70px', transform:'rotate(-15deg)'}}>
                            <i className="fa fa-2x fa-envelope-open text-primary" style={{transform: 'rotate(15deg)'}}></i>
                        </div>
                        <h6 className="mb-0 text-dark">gerencia@sertempco.co</h6>
                    </div>
                </div>
            </div>
            <div className="row mb-5" style={{minHeight:"500px"}}>
                <div className="col-12" style={{maxHeight: '100%', minWidth:'100%'}}>
                    <div className="position-relative h-100">
                        <iframe className="position-relative w-100 h-100"  title='Mapa'
                            src="https://maps.google.com/maps?q=Calle%2079%2047-35,%20%20Barranquilla,%20Atl%C3%A1ntico%20&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>
                    </div>
                </div>
            </div>

            <h2 className="display-4">
                    Pereira</h2>
            <div className="row g-5 mb-5">
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:' 100px', height: '70px', transform:'rotate(-15deg)'}}>
                            <i className="fa fa-2x fa-location-arrow text-primary" style={{transform: 'rotate(15deg)'}}></i>
                        </div>
                        <h6 className="mb-0 text-dark">Calle 19 #8 - 34, piso 5 oficina 09 y 10</h6>
                        <h6 className="mb-0 text-dark"> Edificio corporación financiera</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:' 100px', height: '70px', transform:'rotate(-15deg)'}}>
                            <i className="fa fa-2x fa-phone text-primary" style={{transform: 'rotate(15deg)'}}></i>
                        </div>
                        <h6 className="mb-0 text-dark">3245510201</h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height: '200px'}}>
                        <div className="d-flex align-items-center justify-content-center bg-primary rounded-circle mb-4" style={{width:' 100px', height: '70px', transform:'rotate(-15deg)'}}>
                            <i className="fa fa-2x fa-envelope-open text-primary" style={{transform: 'rotate(15deg)'}}></i>
                        </div>
                        <h6 className="mb-0 text-dark">gerencia@sertempco.co</h6>
                    </div>
                </div>
            </div>
            <div className="row" style={{minHeight:"500px"}}>
                <div className="col-12" style={{maxHeight: '100%', minWidth:'100%'}}>
                    <div className="position-relative h-100">
                        <iframe className="position-relative w-100 h-100"  title='Mapa'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.7465156682406!2d-75.6966223849393!3d4.813530142008409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3887489adac97f%3A0x45e9e9659f290e50!2sCl.%2019%20%238-34%2C%20Pereira%2C%20Risaralda!5e0!3m2!1ses-419!2sco!4v1669047060177!5m2!1ses-419!2sco" 
                            frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center position-relative mb-5" style={{marginTop: '0px', zindex: '1'}}>
                <div className="col-lg-8">
                    <div className="bg-white rounded p-0 m-5 mb-0">
                        <form className='bg-dark'>
                            <div className="row g-3">
                                <p className='text-dark bg-warning h6'>{messageShow.msg}</p>
                                <div className="col-12 col-sm-6">
                                    <input  onChange={handleChange} type="text" className="form-control bg-light border-0" name="nombre" value={contactForm.nombre} text={contactForm.nombre} placeholder="Nombre" style={{height: '55px'}}></input>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input  onChange={handleChange} type="email" className="form-control bg-light border-0" name="correo" value={contactForm.correo} text={contactForm.correo} placeholder="Correo" style={{height: '55px'}}></input>
                                </div>
                                <div className="col-12">
                                    <input  onChange={handleChange} type="text" className="form-control bg-light border-0" name="asunto" value={contactForm.asunto} text={contactForm.asunto} placeholder="Asunto" style={{height: '55px'}}></input>
                                </div>
                                <div className="col-12">
                                    <textarea   onChange={handleChange} className="form-control bg-light border-0" name="mensaje" value={contactForm.mensaje} text={contactForm.mensaje}   rows="5" placeholder="Mensaje"></textarea>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit" onClick={logMeIn} >Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    )
}

export default Contacto