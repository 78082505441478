import React from 'react';

import Contacto from '../components/contacto';


export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }

function Contact () {
        return (
            <>

        
           <Contacto />
          
        {AddLibrary( "./assets/js/main.js")}
        {AddLibrary( "./assets/lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js")}
        {AddLibrary( "./assets/lib/tempusdominus/js/moment-timezone.min.js")}
        {AddLibrary( "./assets/lib/tempusdominus/js/moment.min.js")}
        {AddLibrary( "./assets/lib/owlcarousel/owl.carousel.min.js")}
        {AddLibrary( "./assets/lib/waypoints/waypoints.min.js")}
        {AddLibrary( "./assets/lib/easing/easing.min.js")}
            
            </>
)
}

export default Contact;