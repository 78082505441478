import React from 'react'
import nosotros from './assets/img/Nosotros.jpeg'


function About() {
  return (
    <div>
    <div className="container-fluid py-5 bg-white">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-5 mb-5 mb-lg-0" style={{ maxHeight: '590px', minWidth: '30%'}}>
                    <div className="position-relative h-100">
                        <img className="rounded" src={nosotros} style={{maxWidth:'90%',objectFit:'auto', maxHeight: '100%'}} alt=''></img>
                    </div>
                </div>
                <div className="col-lg-7 mt-6"  >
                    <div className="mb-4">
                        <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Nosotros</h5>
                        <h1 className="display-4 text-dark">Brindamos soluciones efectivas a las empresas</h1>
                    </div>
                    <p className='text-dark' style={{fontSize:'140%', textAlign:'justify'}}>Somos un grupo de profesionales en diferentes áreas, que se unió con el fin de brindar soluciones efectivas a las empresas tales como: suministro de personal, outsourcing en diferentes líneas, entre otras, requeridas para la óptima operación de las compañías.</p>
                </div>
            </div>
        </div>
    </div>
    </div>

    )
}

export default About