import React from 'react'
import {HashLink as Scroll} from 'react-router-hash-link'
import suministro from './assets/img/SUMINISTRO.jpg'
import bpo from './assets/img/BPO.jpeg'
import sst from './assets/img/SST.jpeg'
import headhunter from './assets/img/HEADHUNTER.jpeg'
import outsorcing from './assets/img/OUTSORCING.jpeg'

import bienestar from './assets/img/BIENESTAR.jpeg'



function Descripcionservices() {
  return (
    <div>
    <div className="container-fluid py-5 bg-primary" id='suministro'>
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '350px', minWidth: '50%'}}>
                    <div className="position-relative h-100">
                        <img className=" w-100 h-100 rounded" src={suministro} style={{objectfit: "auto"}} alt=''></img>
                    </div>
                </div>
                <div className="col-lg-6" >
                    <div className="mb-4">
                        <div className="mb-5"></div>
                        <Scroll to='#todoslosservicios'><h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5></Scroll>
                        <h1 className="display-4 text-dark">Suministro de Personal</h1>
                    </div>
                    <p className='text-dark' style={{fontSize:'130%', textAlign:'justify'}}>Nuestra empresa de servicios temporales se convierte en la columna vertebral del departamento de recursos humanos de su compañía, nos especializamos en la provisión, administración y gestión de talento humano en misión, en completa coordinación con su empresa realizamos todos los procesos operativos de selección, contratación y nómina.</p>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5" id='bpo'>
        <div className="container">
            <div className="row gx-5">
                
                <div className="col-lg-6" >
                    <div className="mb-4">
                        <div className="mb-5"></div>
                        <Scroll to='#todoslosservicios'><h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5></Scroll>
                        <h1 className="display-4">Business Process Outsourcing (BPO)</h1>
                    </div>
                    <p style={{fontSize:'130%', textAlign:'justify'}}>Asumimos por usted la gestión administrativa y operativa del capital humano como la contratación, afiliaciones a seguridad, liquidación de aportes, elaboración y pago de nómina, SST e incluso la contabilidad.</p>
                </div>
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '350px', minWidth: '50%'}}>
                    <div className="position-relative h-100">
                        <img className=" w-100 h-100 rounded" src={bpo} style={{objectfit: 'auto'}} alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5  bg-primary" id='seguridad'>
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '350px', minWidth: '50%'}}>
                    <div className="position-relative h-100">
                        <img className=" w-100 h-100 rounded" src={sst} style={{objectfit: 'auto'}} alt=''></img>
                    </div>
                </div>
                <div className="col-lg-6 " >
                    <div className="mb-4">
                        <div className="mb-5"></div>
                        <Scroll to='#todoslosservicios'><h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5></Scroll>
                        <h1 className="display-4 text-dark">Seguridad y Salud en el Trabajo</h1>
                    </div>
                        <ul className='text-dark' style={{fontSize:'130%', textAlign:'justify'}}>
                        <li>Implementación del SGSST</li>
                        <li>Asesorías</li>
                        <li>Auditorias</li>
                        <li>Capacitaciones</li>
                      </ul>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5" id='head'>
        <div className="container">
            <div className="row gx-5">
                
                <div className="col-lg-6 " >
                    <div className="mb-4">
                        <div className="mb-5"></div>
                        <Scroll to='#todoslosservicios'><h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5></Scroll>
                        <h1 className="display-4">Head Hunter (Outsourcing de Selección)</h1>
                    </div>
                    <p style={{fontSize:'130%', textAlign:'justify'}}>Realizamos procesos de selección integrales o fraccionados según la necesidad de su empresa, los cuales abarcan desde el reclutamiento, aplicación de pruebas, entrevistas, visita domiciliaria, estudios de seguridad y poligrafía.</p>
                </div>
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '350px', minWidth: '50%'}}>
                    <div className="position-relative h-100">
                        <img className=" w-100 h-100 rounded" src={headhunter} style={{objectfit: 'auto'}} alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5  bg-primary" id='outsourcing'>
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '350px', minWidth: '50%'}}>
                    <div className="position-relative h-100">
                        <img className=" w-100 h-100 rounded" src={outsorcing} style={{objectfit: 'auto'}} alt=''></img>
                    </div>
                </div>
                <div className="col-lg-6 " >
                    <div className="mb-4">
                        <div className="mb-5"></div>
                        <Scroll to='#todoslosservicios'><h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5></Scroll>
                        <h1 className="display-4 text-dark">Outsourcing Operativo y de Procesos</h1>
                    </div>
                    <ul className='text-dark' style={{fontSize:'130%', textAlign:'justify'}}>
                        <li>Cargue y Descargue</li>
                        <li>Maquila</li>
                        <li>Logística</li>
                        <li>Empaque</li>
                        <li>Aseo</li>
                        <li>Mantenimiento</li>
                      </ul>
                </div>
            </div>
        </div>
    </div>

    




    <div className="container-fluid py-5" id='otros'>
        <div className="container">
            <div className="row gx-5">
                
                <div className="col-lg-6 ">
                    <div className="mb-4">
                        <div className="mb-5"></div>
                        <Scroll to='#todoslosservicios'><h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Servicios</h5></Scroll>
                        <h1 className="display-4">Bienestar</h1>
                    </div>
                    <p style={{fontSize:'130%', textAlign:'justify'}}>Pensamos en cada uno de los colaboradores que gestionan nuestros diferentes procesos, para ellos tenemos beneficios como:</p>
                    <ul style={{fontSize:'130%', textAlign:'justify'}}>
                        <li>Convenio Exequial</li>
                        <li>Convenio Ópticas</li>
                        <li>Actividad Fin de Año</li>
                        <li>Fechas Especiales</li>
                        <li>Brigadas de Salud</li>
                      </ul>
                </div>
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '350px', minWidth: '50%'}}>
                    <div className="position-relative h-100">
                        <img className=" w-100 h-100 rounded" src={bienestar} style={{objectfit: 'auto'}} alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>


    )
}

export default Descripcionservices