import React from 'react'
import {HashLink as Scroll} from 'react-router-hash-link'

function Hero() {
  return (
    <div>
    <div className="container-fluid bg-primary py-5 mb-0 hero-header">
        <div className="container py-5">
            <div className="row justify-content-start">
                <div className="col-lg-8 text-center text-lg-start">
                    <h1 className="display-1 text-white mb-md-4">Especialistas en la gestión y provisión de talento humano</h1>
                    <div className="pt-2">
                        <Scroll to='#todosservices'><a href="#!" className="btn btn-light rounded-pill py-md-3 px-md-5 mx-2">Servicios</a></Scroll>
                        <a href="#!" className="btn btn-outline-light rounded-pill py-md-3 px-md-5 mx-2">Más Información</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}

export default Hero